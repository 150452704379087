import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    props: true,
    component: () => import("@/views/schools/Index.vue")
  },
  {
    path: "/old-schools/:schoolId/:type",
    name: "OldSchool",
    component: () => import("@/views/schools/School.vue")
  },
  {
    path: "/schools/:schoolId",
    name: "School",
    component: () => import("@/views/usNewsSchools/School.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
