import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import userApi from "@/apis/user";

const state = {
  token: "",
  profile: {},
  lang: {
    value: "EN-US",
    title: "English"
  },
  isDuplicate: false,
  finishBind: null
};

const getters = {
  token(state) {
    if (JwtService.hasToken()) {
      // FIXME: 只能透過 mutation 改變 state
      state.token = JwtService.getToken();
    }

    return state.token;
  },
  langValue(state) {
    return state.lang.value;
  },
  langShortValue(state) {
    const langValue = state.lang.value;
    let lang = "";
    if (langValue == "EN-US") {
      lang = "en";
    }
    if (langValue == "ZH-CN") {
      lang = "cn";
    }
    if (langValue == "ZH-TW") {
      lang = "tw";
    }
    return lang;
  },
  getProfile(state) {
    return state.profile;
  },
  getIsPro(state) {
    const roles = state.profile.all_roles || [];
    return roles.includes("pro") || roles.includes("old-pro");
  },
  isIncludeOldPro(state) {
    return (state.profile.all_roles || []).includes("old-pro");
  },
  getProTimeLimited() {
    return (state.profile.taken_unlimited_expire_at || "").split(" ")[0];
  },
  timezone(state) {
    return state.profile.timezone;
  },
  roles(state) {
    return state.profile.all_roles || [];
  },
  isRole: state => role => {
    if (state.profile.all_roles) {
      return state.profile.all_roles.includes(role);
    }
    return false;
  },
  isFreeMember(state) {
    const roles = state.profile.all_roles || [];
    return (
      !roles.includes("super-admin") &&
      !roles.includes("pro") &&
      !roles.includes("old-pro")
    );
  },
  isLogin(state) {
    return state.token !== "";
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setLang(state, lang) {
    state.lang = lang;
  },
  setIsDuplicate(state, isDuplicate) {
    state.isDuplicate = isDuplicate;
  },
  resetToken(state) {
    state.token = "";
  },
  setFinishBind(state, finishBind) {
    state.finishBind = finishBind;
  }
};

const actions = {
  async register({ commit }, payload) {
    const {
      email,
      password,
      captcha,
      captchaKey: captcha_key,
      referral_code
    } = payload;
    const data = { email, password, captcha, captcha_key, referral_code };

    const registerRes = await userApi.register(data);

    const { access_token, user } = registerRes;

    JwtService.saveToken(access_token);

    commit("setToken", access_token);
    commit("setProfile", user);
  },
  async login({ commit }, payload) {
    const { email, password, privacy_info } = payload;

    var data = {};

    if (email.indexOf("@") !== -1) {
      data = { email, password, privacy_info };
    } else {
      data = { username: email, password, privacy_info };
    }

    const loginRes = await userApi.login(data);

    const { access_token, user } = loginRes;

    JwtService.saveToken(access_token);

    commit("setToken", access_token);
    commit("setProfile", user);
  },
  async logout({ commit }) {
    await userApi.logout();

    JwtService.destroyToken();

    commit("setToken", "");
  },
  async getProfile({ commit }) {
    const userRes = await userApi.getProfile();

    commit("setProfile", userRes);
  },
  async checkUsername({ commit }, payload) {
    const { username } = payload;
    const data = { username };

    let isDuplicate = false;

    try {
      await userApi.checkUsernameIsDuplicate(data);

      isDuplicate = false;
    } catch (e) {
      isDuplicate = true;
    }

    commit("setIsDuplicate", isDuplicate);
  },
  async checkFinishBind({ state, commit }) {
    if (state.finishBind === null) {
      let isBinding = await ApiService.post("finish-bind");

      commit("setFinishBind", isBinding.finishBind);
    }
  },
  changeLang({ commit }, lang) {
    commit("setLang", lang);
  },
  resetToken({ commit }) {
    commit("resetToken");
  },
  setToken({ commit }, token) {
    commit("setToken", token);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
